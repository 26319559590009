import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { BrowserUtils, InteractionStatus } from '@azure/msal-browser';

export default function Logout() {
	const { instance, inProgress } = useMsal();

	useEffect(() => {
		if (inProgress === InteractionStatus.None) {
			instance.logoutRedirect({
				onRedirectNavigate: () => !BrowserUtils.isInIframe()
			});
		}
	}, [instance, inProgress]);

	return <LoadingScreen data-testid='Logout' />;
}
