import axios from 'axios';

function getConfig(name, token, data, cancellation) {
	var url = new URL(name, process.env.REACT_APP_PARTNER_PORTAL_API_URL).href;
	var config = {
		method: 'post',
		url: url,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		},
		signal: cancellation ? cancellation.signal : null,
		data: data
	};

	return config;
}

export const createLoanContract = (token, request) => {
	var data = JSON.stringify({
		createLoanContract: request
	});

	var config = getConfig('CreateLoanContract', token, data);

	return axios(config);
};

export const retrieveLoanPartnerLoans = (token, cancellation = null) => {
	var config = getConfig('RetrieveLoanPartnerLoans', token, {}, cancellation);

	return axios(config);
};

export const retrieveLoanPQLettersDetails = (token, request, cancellation = null) => {
	var data = JSON.stringify({
		retrieveLoanPQLettersDetails: request
	});

	var config = getConfig('RetrieveLoanPQLettersDetails', token, data, cancellation);

	return axios(config);
};

export const retrievePartnerPrequalLetterDocument = (token, request) => {
	var data = JSON.stringify({
		retrievePartnerDocument: request
	});

	var config = getConfig('RetrievePartnerPrequalLetterDocument', token, data);

	return axios(config);
};

export const retrievePartnerContractDocument = (token, request) => {
	var data = JSON.stringify({
		retrievePartnerDocument: request
	});

	var config = getConfig('RetrievePartnerContractDocument', token, data);

	return axios(config);
};
