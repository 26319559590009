import React from 'react';
import PropTypes from 'prop-types';
import styles from './DotLabel.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const DotLabel = (props) => (
	<div className={styles.DotLabel} data-testid='DotLabel'>
		<FontAwesomeIcon icon={faCircle} color={props.dotColor} className={styles.Dot} />
		<span>{props.text}</span>
	</div>
);

DotLabel.propTypes = {
	dotColor: PropTypes.string,
	text: PropTypes.string
};

DotLabel.defaultProps = {
	dotColor: 'black'
};

export default DotLabel;
