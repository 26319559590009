import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { accountCanBeSetAsActive, getAccountToSetAsActive } from './accountUtils';
import {
	InteractionRequiredAuthError,
	InteractionStatus,
	BrowserAuthError
} from '@azure/msal-browser';

export function useToken() {
	// Constants
	const expiryCheckTimeoutInMs = 60000;

	// Hooks
	const [token, setToken] = useState(null);
	const [JWTExpirationCount, setJWTExpirationCount] = useState(0);
	const { instance, inProgress, accounts } = useMsal();

	useEffect(() => {
		let isMounted = true;
		let activeAccount = instance.getActiveAccount();

		const acquireToken = async (tokenRequest) => {
			await instance.handleRedirectPromise();

			instance
				.acquireTokenSilent(tokenRequest)
				.then((response) => {
					if (isMounted) {
						setToken(response.idToken);
					}
				})
				.catch(async (error) => {
					if (error instanceof InteractionRequiredAuthError) {
						console.log('Interaction required.');
						await instance.acquireTokenRedirect(tokenRequest);
					} else if (
						error instanceof BrowserAuthError &&
						error?.errorCode === 'post_request_failed'
					) {
						return console.log('Expected auth failure');
					} else {
						console.error(error);
					}
				});
		};

		if (!activeAccount || !accountCanBeSetAsActive(activeAccount)) {
			const accountToSetAsActive = getAccountToSetAsActive(accounts);

			if (accountToSetAsActive) {
				activeAccount = accountToSetAsActive;
				instance.setActiveAccount(activeAccount);
			}
		}

		if (activeAccount && inProgress === InteractionStatus.None) {
			const tokenRequest = {
				...loginRequest,
				account: activeAccount
			};
			acquireToken(tokenRequest);
		}

		return () => {
			isMounted = false;
		};
	}, [instance, accounts, inProgress, JWTExpirationCount]);

	useEffect(() => {
		const expiryCheckInterval = setInterval(() => {
			const activeAccount = instance.getActiveAccount();

			if (
				activeAccount &&
				new Date(activeAccount.idTokenClaims?.exp * 1000) - new Date() <= expiryCheckTimeoutInMs
			) {
				setJWTExpirationCount(JWTExpirationCount + 1);
			}
		}, expiryCheckTimeoutInMs); // Run expiry check every minute

		return () => clearInterval(expiryCheckInterval);
	}, [JWTExpirationCount, instance]);

	return token;
}
