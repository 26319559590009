import axios from 'axios';

function getConfig(name, data, cancellation) {
	var url = new URL(name, process.env.REACT_APP_BORROWER_PORTAL_PUBLIC_API_URL).href;
	var config = {
		method: 'post',
		url: url,
		headers: {
			'Content-Type': 'application/json'
		},
		signal: cancellation ? cancellation.signal : null,
		data: data
	};
	return config;
}

export const retrievePartnerInviteEmail = (request) => {
	var data = JSON.stringify(request);
	var config = getConfig('RetrievePartnerInviteEmail', data);
	return axios(config);
};

export const retrieveCompanyDocument = (request) => {
	var data = JSON.stringify(request);
	var config = getConfig('RetrieveCompanyDocument', data);
	return axios(config);
};
