import React, { useEffect, useState } from 'react';
import styles from './DocumentViewer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	retrievePartnerContractDocument,
	retrievePartnerPrequalLetterDocument
} from '../../../utils/partnerPortalApi';
import { useToken } from '../../../utils/tokenUtils';
import { base64ToArrayBuffer } from '../../../utils/utils';
import { ViewingType } from '../../../constants/DocumentViewerConstants';
import { retrieveCompanyDocument } from '../../../utils/borrowerPortalPublicApi';

const storageName = 'documentViewerData';

const getState = (state) => {
	if (state) {
		sessionStorage.setItem(storageName, btoa(JSON.stringify(state)));
		return state;
	} else {
		var encodedData = sessionStorage.getItem(storageName);
		if (encodedData) {
			return JSON.parse(atob(encodedData));
		} else {
			return null;
		}
	}
};

function getPartnerDocumentAsObjectUrl(token, bpicolid, setData, viewingType) {
	if (token == null) return;

	var request = {
		request: {
			bPICOLID: bpicolid
		}
	};

	var retrievePartnerDocumentEndpoint;
	switch (viewingType) {
		case ViewingType.PrequalLetterDocument:
			retrievePartnerDocumentEndpoint = retrievePartnerPrequalLetterDocument;
			break;
		case ViewingType.ContractDocument:
			retrievePartnerDocumentEndpoint = retrievePartnerContractDocument;
			break;
		default:
			console.error('Invalid document viewing type.');
			return;
	}

	retrievePartnerDocumentEndpoint(token, request)
		.then((response) => {
			var fileData =
				response.data.retrievePartnerDocumentResponse.retrievePartnerDocumentResult.document;
			var blob = new Blob([base64ToArrayBuffer(fileData)], { type: 'application/pdf' });
			var fileUrl = URL.createObjectURL(blob);
			setData(fileUrl);
		})
		.catch((err) => {
			console.error(err);
		});
}

function getLegalDocumentAsHtml(documentType, id, setData) {
	var request = {
		request: {
			DocumentType: documentType,
			Identifier: id
		}
	};

	retrieveCompanyDocument(request)
		.then((response) => {
			var fileData = response.data.RetrieveCompanyDocumentResult.Document;

			var uint8Array = new Uint8Array(fileData.length);
			for (var i = 0; i < uint8Array.length; i++) {
				uint8Array[i] = fileData[i];
			}
			const decoder = new TextDecoder();
			setData(decoder.decode(uint8Array.buffer));
		})
		.catch((err) => {
			console.error(err);
		});
}

export const documentViewerCleanup = () => {
	sessionStorage.removeItem(storageName);
};

const DocumentViewer = () => {
	let navigate = useNavigate();
	let location = useLocation();
	let token = useToken();

	const [fileUrl, setFileUrl] = useState(null);
	const [htmlData, setHtmlData] = useState(null);

	useEffect(() => {
		return () => {
			if (fileUrl) {
				URL.revokeObjectURL(fileUrl);
			}
		};
	}, [fileUrl]);

	useEffect(() => {
		const state = getState(location.state);
		const documentTypes = [ViewingType.PrequalLetterDocument, ViewingType.ContractDocument];
		if (token != null && state && documentTypes.some((type) => type === state.type)) {
			getPartnerDocumentAsObjectUrl(token, state.bpicolid, setFileUrl, state.type);
		} else if (state && state.type === ViewingType.LegalDocument) {
			getLegalDocumentAsHtml(state.documentTypeId, state.companyId, setHtmlData);
		}
	}, [token, location.state]);

	return (
		<div id={styles.PdfContent} data-testid='DocumentViewer'>
			<header className={styles.ButtonBack}>
				<span
					onClick={() => {
						let state = getState(location.state);
						if (state && state.returnPage) {
							navigate(state.returnPage);
						} else {
							navigate(-1);
						}
						documentViewerCleanup();
					}}>
					<FontAwesomeIcon icon={faCaretLeft} />
					&nbsp;BACK
				</span>
			</header>
			{fileUrl && (
				<iframe
					title='PDF viewer'
					className={styles.DocumentFrame}
					src={`./pdf/web/viewer.html?file=${fileUrl}`}
				/>
			)}
			{htmlData && (
				<iframe
					title='Html Frame'
					className={styles.DocumentFrame}
					src={'data:text/html;charset=utf-8,' + encodeURIComponent(htmlData)}
				/>
			)}
		</div>
	);
};

DocumentViewer.propTypes = {};

DocumentViewer.defaultProps = {};

export default DocumentViewer;
