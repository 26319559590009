import PropTypes from 'prop-types';
import styles from './LoadingScreen.module.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import LoaderIcon from '@houseloan/loanfly-ui/components/pageElements/LoaderIcon';
import LoanFlyLogoPage from '@houseloan/loanfly-ui/components/pages/LoanFlyLogoPage';

library.add(faCircleNotch);

const LoadingScreen = () => {
	return (
		<div id={styles.LoadingScreen} data-testid='LoadingScreen'>
			<LoanFlyLogoPage backgroundImageSrc={'../../../images/background.jpg'}>
				<div>
					<LoaderIcon />
				</div>
			</LoanFlyLogoPage>
		</div>
	);
};

LoadingScreen.propTypes = {
	props: PropTypes.object
};

LoadingScreen.defaultProps = {
	props: {}
};

export default LoadingScreen;
