import { useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { matchPath } from 'react-router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faListUl, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import LoanFlyLayout, {
	useLoanFlyLayout
} from '@houseloan/loanfly-ui/components/layouts/LoanFlyLayout';
import { MenuItemDetails } from '@houseloan/loanfly-ui/components/pageElements/SideMenu';
import SearchBox from '@houseloan/loanfly-ui/components/pageElements/SearchBox';
import searchFullPageContext from '../../../utils/searchFullPageContext';
import { RoutePaths } from '../../../constants/RouteConstants';
import useAuthenticationWrapper from '../../../utils/useAuthenticationWrapper';
import { AuthenticationState } from '../../../constants/AuthenticationStateConstants';

library.add(faListUl, faSignOutAlt);

const pqPipelineItem = new MenuItemDetails(null, 'PQ Pipeline', RoutePaths.Dashboard.path);
pqPipelineItem.otherMatchedPaths = ['/'];

const SideMenuDetails = (msalInstance) => [
	new MenuItemDetails('list-ul', 'My Pipeline', undefined, [pqPipelineItem]),
	new MenuItemDetails('sign-out-alt', 'Logout', '#', undefined, () => {
		msalInstance.logoutRedirect();
	})
];

const SideMenuFooterDetails = [
	new MenuItemDetails(
		null,
		'Privacy Policy',
		RoutePaths.PrivacyDisclosures.path.replace(':documentTypeId', 2)
	),
	new MenuItemDetails(
		null,
		'Disclosures',
		RoutePaths.PrivacyDisclosures.path.replace(':documentTypeId', 0)
	),
	new MenuItemDetails(
		null,
		'Licenses',
		RoutePaths.PrivacyDisclosures.path.replace(':documentTypeId', 1)
	)
];

const LayoutChild = ({ authenticationState, currentPath, children }) => {
	const { setFullscreen } = useLoanFlyLayout();

	useLayoutEffect(() => {
		if (authenticationState !== AuthenticationState.Authenticated) {
			setFullscreen(true);
			return;
		}

		for (const [, value] of Object.entries(RoutePaths)) {
			if (
				matchPath(
					{
						path: value.path,
						caseSensitive: false,
						end: true
					},
					currentPath
				)
			) {
				setFullscreen(!value.hasSidebar);
			}
		}
	}, [setFullscreen, authenticationState, currentPath]);

	return children;
};

const PartnerPortalLayout = ({ children }) => {
	const location = useLocation();
	const { instance } = useMsal();
	const [globalSearch, setGlobalSearch] = useState('');
	const authenticationState = useAuthenticationWrapper();

	return (
		<LoanFlyLayout
			title='Partner Portal'
			logoLinkPath={RoutePaths.Dashboard.path}
			menuItems={SideMenuDetails(instance)}
			footerMenuItems={SideMenuFooterDetails}
			headerChildren={<SearchBox search={globalSearch} searchSetter={setGlobalSearch} />}
			defaultFullscreen={true}>
			<LayoutChild authenticationState={authenticationState} currentPath={location.pathname}>
				<searchFullPageContext.Provider value={{ searchParameter: globalSearch }}>
					{children}
				</searchFullPageContext.Provider>
			</LayoutChild>
		</LoanFlyLayout>
	);
};

PartnerPortalLayout.propTypes = {};

PartnerPortalLayout.defaultProps = {};

export default PartnerPortalLayout;
