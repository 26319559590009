export class RouteObject {
	constructor(path, hasSidebar = false) {
		this.path = path;
		this.hasSidebar = hasSidebar;
	}
}

export const RoutePaths = Object.freeze({
	Default: new RouteObject('/', true),
	Logout: new RouteObject('/logout'),
	Dashboard: new RouteObject('/dashboard', true),
	SignUp: new RouteObject('/signUp/:inviteId?'),
	ViewDocument: new RouteObject('/document'),
	PrivacyDisclosures: new RouteObject('/privacydisclosures/:documentTypeId', false)
});
