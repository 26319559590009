import './index.css';
import App from './App';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-app-polyfill/stable';
import { msalConfig } from './authConfig';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorFallback from './components/pageElements/ErrorFallback/ErrorFallback';
import { accountCanBeSetAsActive, getAccountToSetAsActive } from './utils/accountUtils';
import {
	PublicClientApplication,
	EventType,
	BrowserUtils,
	BrowserAuthError
} from '@azure/msal-browser';
import { documentViewerCleanup } from './components/pages/DocumentViewer/DocumentViewer';
import { setAuthTimeToNow, removeAuthTime } from './utils/useAuthenticationWrapper';

export const msalInstance = new PublicClientApplication(msalConfig);

const isJWTExpired = (activeAccount) => {
	if (activeAccount) {
		// The JWT token's 'exp' value refers to an Epoch timestamp
		return new Date(activeAccount.idTokenClaims?.exp * 1000) <= new Date();
	}
};

// Check to prevent block_iframe_reload error
if (!BrowserUtils.isInIframe()) {
	// Get a list of all available accounts
	const accounts = msalInstance.getAllAccounts();

	// Get the currently active account
	const activeAccount = msalInstance.getActiveAccount();

	if (isJWTExpired(activeAccount)) {
		localStorage.clear();
	}

	// Use a default account if no account is active on page load
	if (!activeAccount && accounts.length > 0) {
		// Defaults to the account having either the 'Sign in' or 'Sign up' TFP
		const defaultAccount = getAccountToSetAsActive(accounts);

		if (defaultAccount) {
			msalInstance.setActiveAccount(defaultAccount);
		}
	}

	// This will update account state if a user signs in from another tab or window
	msalInstance.enableAccountStorageEvents();

	msalInstance.addEventCallback((event) => {
		if (
			event.eventType === EventType.ACCOUNT_REMOVED ||
			/* If the user clicked the cancel button, redirect to logout */
			(event.eventType === EventType.LOGIN_FAILURE &&
				event.error?.errorMessage &&
				!event.error?.errorMessage.indexOf('AADB2C90091'))
		) {
			msalInstance.logoutRedirect({
				onRedirectNavigate: () => !BrowserUtils.isInIframe()
			});
		}

		if (
			event.eventType === EventType.LOGIN_FAILURE &&
			event.error instanceof BrowserAuthError &&
			event.error?.errorCode === 'no_cached_authority_error'
		) {
			window.location.href = process.env.REACT_APP_URL;
		}

		if (event.eventType === EventType.LOGIN_SUCCESS) {
			setAuthTimeToNow();

			if (event.payload.account) {
				const account = event.payload.account;

				if (accountCanBeSetAsActive(account)) {
					msalInstance.setActiveAccount(account);
				}
			}
		} else if (event.eventType === EventType.LOGOUT_START) {
			documentViewerCleanup();
		} else if (event.eventType === EventType.LOGOUT_SUCCESS) {
			removeAuthTime();
		}
	});

	msalInstance.handleRedirectPromise();

	const appErrorHandler = (error) => {
		console.error(error.toString());
	};

	const appErrorFallback = ({ error }) => {
		return <ErrorFallback error={error} />;
	};

	const root = createRoot(document.getElementById('root'));
	root.render(
		<React.StrictMode>
			<Router>
				{/* Catches errors originating from React components */}
				<ErrorBoundary FallbackComponent={appErrorFallback} onError={appErrorHandler}>
					<App msalInstance={msalInstance} />
				</ErrorBoundary>
			</Router>
		</React.StrictMode>
	);

	// If you want to start measuring performance in your app, pass a function
	// to log results (for example: reportWebVitals(console.log))
	// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
	reportWebVitals();
}
