export const CreditStatusCategories = Object.freeze({
	Yellow: { label: 'Yellow', color: 'Yellow' },
	Green: { label: 'Green', color: 'Green' },
	Red: { label: 'Red', color: 'Red' },
	Gray: { label: 'Pending Credit', color: 'Gray' },
	Blue: { label: 'In Process', color: 'Blue' }
});

export const LoanStatuses = Object.freeze({
	Application: 20
});
