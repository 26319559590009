import React from 'react';
import './App.module.css';
import './styles/Colors.css';
import { MsalProvider } from '@azure/msal-react';
import { RoutePaths } from './constants/RouteConstants';
import { Route, Routes, Navigate } from 'react-router-dom';
import ProtectedRoute from './components/routes/ProtectedRoute';

import Logout from './components/pages/Logout/Logout';
import SignUpPage from './components/pages/SignUpPage/SignUpPage';
import DocumentViewer from './components/pages/DocumentViewer/DocumentViewer';
import PrequalPipeline from './components/pages/PrequalPipeline/PrequalPipeline';
import PrivacyDisclosure from './components/pages/PrivacyDisclosure/PrivacyDisclosure';

// Layouts
import PartnerPortalLayout from './components/layouts/PartnerPortalLayout/PartnerPortalLayout';

const prequalPipelineElement = (
	<ProtectedRoute>
		<PrequalPipeline />
	</ProtectedRoute>
);
function App({ msalInstance }) {
	return (
		<MsalProvider instance={msalInstance}>
			<PartnerPortalLayout>
				<Routes>
					<Route path={RoutePaths.Default.path} element={prequalPipelineElement} />
					<Route path={RoutePaths.Dashboard.path} element={prequalPipelineElement} />
					<Route path={RoutePaths.SignUp.path} element={<SignUpPage />} />
					<Route path={RoutePaths.ViewDocument.path} element={<DocumentViewer />} />
					<Route path={RoutePaths.Logout.path} element={<Logout />} />
					<Route path={RoutePaths.PrivacyDisclosures.path} element={<PrivacyDisclosure />} />
					<Route path='*' element={<Navigate replace to={RoutePaths.Default.path} />} />
				</Routes>
			</PartnerPortalLayout>
		</MsalProvider>
	);
}

export default App;
