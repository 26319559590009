import 'abortcontroller-polyfill';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import escapeRegExp from 'lodash/escapeRegExp';
import classNames from 'classnames';
import { CreditStatusColors, PipelineTableColors } from '../../../constants/ColorConstants';
import { ViewingType } from '../../../constants/DocumentViewerConstants';
import { CreditStatusCategories, LoanStatuses } from '../../../constants/PipelineConstants';
import { RoutePaths } from '../../../constants/RouteConstants';
import styles from './PrequalPipeline.module.css';
import {
	createLoanContract,
	retrieveLoanPQLettersDetails,
	retrieveLoanPartnerLoans
} from '../../../utils/partnerPortalApi';
import { useToken } from '../../../utils/tokenUtils';
import searchFullPageContext from '../../../utils/searchFullPageContext';
import { faEye, faFileContract } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltSquareUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import ButtonIcon from '@houseloan/loanfly-ui/components/pageElements/ButtonIcon';
import Card from '@houseloan/loanfly-ui/components/pageElements/Card';
import UploadModal from '@houseloan/loanfly-ui/components/pageElements/UploadModal';
import Modal from '@houseloan/loanfly-ui/components/pageElements/Modal';
import Table from '@houseloan/loanfly-ui/components/pageElements/Table';
import ResponsiveTooltip from '@houseloan/loanfly-ui/components/pageElements/ResponsiveTooltip';
import Button from '@houseloan/loanfly-ui/components/pageElements/Button';
import DotLabel from '../../pageElements/DotLabel/DotLabel';

library.add(faEye, faArrowAltSquareUp);

// Credit score upper bounds (based on Borrower Portal)
const redMaxCreditScore = 619;
const yellowMaxCreditScore = 699;

const matchAll = require('string.prototype.matchall');
matchAll.shim();

const filterColumns = ['borrowerName', 'coBorrowerName', 'pqDate', 'loanStatus'];

const getStatusColor = (creditScore, isProcessing) => {
	if (isProcessing) {
		return CreditStatusColors.Blue;
	}

	if (!creditScore) {
		return CreditStatusColors.Gray;
	}

	// Needs improvement & Fair
	if (creditScore <= redMaxCreditScore) {
		return CreditStatusColors.Red;
	}

	// Good
	if (creditScore <= yellowMaxCreditScore) {
		return CreditStatusColors.Yellow;
	}

	// Great & Excellent
	return CreditStatusColors.Green;
};

const isLoanInProcessing = (loanStatusId) => {
	return loanStatusId === LoanStatuses.Application;
};

const generateStatusFilter = () => {
	var result = {};
	for (var statusCategory in CreditStatusCategories) {
		result[CreditStatusCategories[statusCategory].color] = false;
	}
	return result;
};

const PrequalPipeline = () => {
	const navigate = useNavigate();
	const authorizationToken = useToken();

	const [loans, setLoans] = useState([]);
	const [tableRows, setTableRows] = useState([]);
	const [summaryData, setSummaryData] = useState({});
	const [isLoadingLoans, setIsLoadingLoans] = useState(true);
	const [filteredRows, setFilteredRows] = useState(undefined);
	const [contractUploadLoanId, setContractUploadLoanId] = useState(null);
	const [statusfilters, setStatusFilters] = useState(generateStatusFilter());
	const [prequalLetters, setPrequalLetters] = useState(undefined);
	const [prequalLetterRows, setPrequalLetterRows] = useState(undefined);

	let { searchParameter } = useContext(searchFullPageContext);

	const tableColumns = [
		{
			Header: '',
			accessor: 'dotLabel'
		},
		{
			Header: 'Borrower Name',
			accessor: 'borrowerName'
		},
		{
			Header: 'Co-Borrower Name',
			accessor: 'coBorrowerName'
		},
		{
			Header: 'Status',
			accessor: 'loanStatus'
		},
		{
			Header: 'PQ Date',
			accessor: 'pqDate'
		},
		{
			Header: 'Credit Score',
			accessor: 'creditScore'
		},
		{
			Header: 'PQ Letter',
			accessor: 'pqLetter'
		},
		{
			Header: 'Sales Price',
			accessor: 'salesPrice'
		},
		{
			Header: 'Contract',
			accessor: 'contract'
		}
	];

	const clone = (array) => array.map((object) => ({ ...object }));

	const onfilterCardClick = (card) => {
		var updatedFilters = { ...statusfilters };
		updatedFilters[card] = !updatedFilters[card];
		setStatusFilters(updatedFilters);
	};

	const viewDocumentClickHandler = useCallback(
		(bpicolid, viewingType) => {
			navigate(RoutePaths.ViewDocument.path, {
				state: {
					type: viewingType,
					bpicolid: bpicolid,
					returnPage: RoutePaths.Dashboard.path
				}
			});
		},
		[navigate]
	);

	const viewPrequalLettersClickHandler = useCallback(
		(loanId) => {
			if (authorizationToken != null) {
				const cancellation = new AbortController();
				let request = { request: { loanId: loanId } };

				retrieveLoanPQLettersDetails(authorizationToken, request, cancellation)
					.then((response) => {
						var letters =
							response?.data?.retrieveLoanPQLettersDetailsResponse
								?.retrieveLoanPQLettersDetailsResult?.Letters;

						if (letters) {
							setPrequalLetters(
								letters.map((pq) => {
									return {
										PQLetterId: pq.BPIColId,
										PQLetterName:
											pq.loanNumber +
											' - ' +
											new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
												pq.loanAmount
											) +
											' - ' +
											new Date(pq.expires).toLocaleDateString('en-US', {
												year: '2-digit',
												month: '2-digit',
												day: '2-digit'
											})
									};
								})
							);
						} else {
							setPrequalLetters(undefined);
						}
					})
					.catch((error) => {
						console.error(error);
					});

				return () => {
					cancellation.abort(); // Abort any requests during cleanup
				};
			}
		},
		[authorizationToken]
	);

	useEffect(() => {
		var result = undefined;

		if (searchParameter) {
			var searchRegex = new RegExp(escapeRegExp(searchParameter), 'ig');
			result = clone(tableRows).filter(
				(r) =>
					filterColumns.filter(
						(column) => r[column] !== null && `${r[column]}`.search(searchRegex) >= 0
					).length
			);
			result.forEach((r) => {
				filterColumns.forEach((column) => {
					if (r[column] !== null && `${r[column]}`.search(searchRegex) >= 0) {
						var matches = [...`${r[column]}`.matchAll(searchRegex)];
						var matchesIndex = 0;
						var spanKeyId = 0;
						var markKeyId = 0;
						var value = `${r[column]}`
							.split(searchRegex)
							.map((b) => <span key={'span-' + spanKeyId++}>{b}</span>);
						r[column] = value.reduce((accu, elem) => {
							return accu === null
								? [elem]
								: [
										...accu,
										<mark key={'mark-' + markKeyId++}>{matches[matchesIndex++]}</mark>,
										elem
								  ];
						}, null);
					}
				});
			});
		}

		var statusFiltersActive = false;
		for (const creditScoreCategory in statusfilters) {
			statusFiltersActive |= statusfilters[creditScoreCategory];
		}

		if (statusFiltersActive) {
			result = result ?? clone(tableRows);
			for (const creditScoreCategory in statusfilters) {
				if (!statusfilters[creditScoreCategory]) {
					result = result.filter((r) => r.statusColor !== CreditStatusColors[creditScoreCategory]);
				}
			}
		}

		setFilteredRows(result);
	}, [searchParameter, statusfilters, tableRows]);

	useEffect(() => {
		if (authorizationToken != null) {
			const cancellation = new AbortController();

			retrieveLoanPartnerLoans(authorizationToken, cancellation)
				.then((response) => {
					var loans =
						response?.data?.retrieveLoanPartnerLoansResponse?.retrieveLoanPartnerLoansResult?.Loans;

					if (loans) {
						setIsLoadingLoans(false);
						setLoans(loans);
					} else {
						setLoans([]);
					}
				})
				.catch((error) => {
					console.error(error);
				});

			return () => {
				cancellation.abort(); // Abort any requests during cleanup
			};
		}
	}, [authorizationToken]);

	useEffect(() => {
		var rows = [];
		if (loans && loans.length > 0) {
			rows = loans.map((loanDetails) => {
				const isProcessing = isLoanInProcessing(loanDetails.loanStatusId);
				const statusColor = getStatusColor(loanDetails.creditScore, isProcessing);

				return {
					statusColor: statusColor,
					dotLabel: <DotLabel dotColor={statusColor} />,
					borrowerName: loanDetails.borrowerName,
					coBorrowerName: loanDetails.coborrowerName,
					loanStatus: loanDetails.loanStatus,
					pqDate: loanDetails.PQDate
						? new Date(loanDetails.PQDate).toLocaleDateString('en-US', {
								year: '2-digit',
								month: '2-digit',
								day: '2-digit'
						  })
						: null,
					creditScore: loanDetails.creditScore ?? '-',
					pqLetter: loanDetails.sharePrequalLetter ? (
						<ResponsiveTooltip
							rootAppElementId='root'
							tooltipText={loanDetails.PQLetterCount ? '' : 'No PQ Letters available'}>
							<ButtonIcon
								fontAwesomeIcon='eye'
								primaryColor={PipelineTableColors.BlueIcon}
								onClick={() =>
									loanDetails.PQLetterCount && viewPrequalLettersClickHandler(loanDetails.loanId)
								}
								disabled={!loanDetails.PQLetterCount}
							/>
						</ResponsiveTooltip>
					) : (
						'-'
					),
					salesPrice: loanDetails.salesPrice
						? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
								loanDetails.salesPrice
						  )
						: null,
					contract: (
						<>
							{loanDetails.contractDocumentId && (
								<>
									<ButtonIcon
										fontAwesomeIcon='eye'
										primaryColor={PipelineTableColors.BlueIcon}
										onClick={() =>
											viewDocumentClickHandler(
												loanDetails.contractDocumentId,
												ViewingType.ContractDocument
											)
										}
									/>
									&nbsp;
								</>
							)}
							<ButtonIcon
								fontAwesomeIcon='arrow-alt-square-up'
								primaryColor={PipelineTableColors.BlueIcon}
								onClick={() => setContractUploadLoanId(loanDetails.loanId)}
							/>
						</>
					)
				};
			});
		}
		setTableRows(rows);
	}, [loans, viewDocumentClickHandler, viewPrequalLettersClickHandler]);

	useEffect(() => {
		const groupedData = mapValues(
			groupBy(tableRows, (row) => row.statusColor),
			(v) => v.length
		);
		setSummaryData(groupedData);
	}, [tableRows]);

	useEffect(() => {
		if (prequalLetters?.length === 1) {
			navigate(RoutePaths.ViewDocument.path, {
				state: {
					type: ViewingType.PrequalLetterDocument,
					bpicolid: prequalLetters[0].PQLetterId,
					returnPage: RoutePaths.Dashboard.path
				}
			});
		} else {
			setPrequalLetterRows(
				prequalLetters?.map((pq) => {
					return {
						PQLetterName: pq.PQLetterName,
						ViewPQLetter: (
							<ButtonIcon
								fontAwesomeIcon='eye'
								primaryColor={PipelineTableColors.BlueIcon}
								onClick={() =>
									viewDocumentClickHandler(pq.PQLetterId, ViewingType.PrequalLetterDocument)
								}
							/>
						)
					};
				})
			);
		}
	}, [prequalLetters, viewDocumentClickHandler, navigate]);

	const submitFile = async (document) => {
		if (authorizationToken === null) {
			return Promise.reject(new Error('Unauthorized'));
		}

		// If authorization token is valid
		const response = await createLoanContract(authorizationToken, {
			request: {
				document: document,
				loanId: contractUploadLoanId
			}
		});
		if (
			response.status === 200 &&
			response.data.createLoanContractResponse.createLoanContractResult.status === 'Success'
		) {
			loans.find((l) => l.loanId === contractUploadLoanId).contractDocumentId =
				response.data.createLoanContractResponse.createLoanContractResult.borrowerPortalItemCollectionId;
			setLoans([...loans]);

			return;
		}
		return await Promise.reject(new Error('An error occurred during loan contract submission.'));
	};

	const getDotLabelText = (labelValue) => (labelValue ?? 0).toString();

	return (
		<div className={styles.PrequalPipeline} data-testid='PrequalPipeline' id='PrequalPipelineId'>
			<div id={styles.ExecSummaryContainer}>
				<Card
					className={`${styles.CreditScoreCard} ${styles.Clickable} ${
						statusfilters[CreditStatusCategories.Gray.color] ? styles.CreditScoreCardActive : null
					}`}
					onClick={() => onfilterCardClick(CreditStatusCategories.Gray.color)}>
					<p className={styles.CreditScoreCardTitle}>{CreditStatusCategories.Gray.label}</p>
					<DotLabel
						dotColor={CreditStatusColors.Gray}
						text={getDotLabelText(summaryData[CreditStatusColors.Gray])}
						border={statusfilters[CreditStatusCategories.Gray.color]}
					/>
				</Card>
				<Card
					className={`${styles.CreditScoreCard} ${styles.Clickable} ${
						statusfilters[CreditStatusCategories.Green.color] ? styles.CreditScoreCardActive : null
					}`}
					onClick={() => onfilterCardClick(CreditStatusCategories.Green.color)}>
					<p className={styles.CreditScoreCardTitle}>{CreditStatusCategories.Green.label}</p>
					<DotLabel
						dotColor={CreditStatusColors.Green}
						text={getDotLabelText(summaryData[CreditStatusColors.Green])}
						border={statusfilters[CreditStatusCategories.Green.color]}
					/>
				</Card>
				<Card
					className={`${styles.CreditScoreCard} ${styles.Clickable} ${
						statusfilters[CreditStatusCategories.Yellow.color] ? styles.CreditScoreCardActive : null
					}`}
					onClick={() => onfilterCardClick(CreditStatusCategories.Yellow.color)}>
					<p className={styles.CreditScoreCardTitle}>{CreditStatusCategories.Yellow.label}</p>
					<DotLabel
						dotColor={CreditStatusColors.Yellow}
						text={getDotLabelText(summaryData[CreditStatusColors.Yellow])}
						border={statusfilters[CreditStatusCategories.Yellow.color]}
					/>
				</Card>
				<Card
					className={`${styles.CreditScoreCard} ${styles.Clickable} ${
						statusfilters[CreditStatusCategories.Red.color] ? styles.CreditScoreCardActive : null
					}`}
					onClick={() => onfilterCardClick(CreditStatusCategories.Red.color)}>
					<p className={styles.CreditScoreCardTitle}>{CreditStatusCategories.Red.label}</p>
					<DotLabel
						dotColor={CreditStatusColors.Red}
						text={getDotLabelText(summaryData[CreditStatusColors.Red])}
						border={statusfilters[CreditStatusCategories.Red.color]}
					/>
				</Card>
				<Card
					className={`${styles.CreditScoreCard} ${styles.Clickable} ${
						statusfilters[CreditStatusCategories.Blue.color] ? styles.CreditScoreCardActive : null
					}`}
					onClick={() => onfilterCardClick(CreditStatusCategories.Blue.color)}>
					<p className={styles.CreditScoreCardTitle}>{CreditStatusCategories.Blue.label}</p>
					<DotLabel
						dotColor={CreditStatusColors.Blue}
						text={getDotLabelText(summaryData[CreditStatusColors.Blue])}
						border={statusfilters[CreditStatusCategories.Blue.color]}
					/>
				</Card>
			</div>
			<Card
				header='Your Pipeline'
				className={styles.YourPipeline}
				bodyClassName={styles.TableContainer}>
				<Table
					columns={tableColumns}
					rows={filteredRows ? filteredRows : tableRows}
					loading={isLoadingLoans}
					noDataText='There are no loans to display on this pipeline.'
				/>
			</Card>
			<UploadModal
				title='Contract Upload'
				rootAppElementId='root'
				onSubmit={submitFile}
				onCancel={() => setContractUploadLoanId(null)}
				onClose={() => setContractUploadLoanId(null)}
				isOpen={contractUploadLoanId !== null}
				subtitle='Contract files must be in PDF, PNG or JPG format.'
				submissionErrorMessage='ERROR: Failed to submit loan contract.'
			/>
			<Modal
				rootAppElementId='root'
				isOpen={prequalLetterRows !== undefined && prequalLetterRows.length > 1}
				className={classNames(styles.PrequalLettersModal, styles.Override)}
				onClose={() => setPrequalLetters(undefined)}>
				<FontAwesomeIcon
					icon={faFileContract}
					id={styles.FileContractIcon}
					color={PipelineTableColors.BlueIcon}
				/>
				<h2>Prequal Letters</h2>
				<p>Select any of the below Prequalification Letters for viewing.</p>
				{prequalLetterRows ? (
					<Table
						className={styles.TableContainerOverride}
						columns={[
							{
								Header: '',
								accessor: 'PQLetterName'
							},
							{
								Header: '',
								accessor: 'ViewPQLetter'
							}
						]}
						rows={prequalLetterRows}
					/>
				) : (
					<></>
				)}
				<Button
					className={styles.CancelButton}
					onClick={() => setPrequalLetters(undefined)}
					text='Close'
				/>
			</Modal>
		</div>
	);
};

PrequalPipeline.propTypes = {};

PrequalPipeline.defaultProps = {};

export default PrequalPipeline;
