export const GlobalModalColors = Object.freeze({
	Overlay: {
		Background: 'rgba(100, 100, 100, 0.25)'
	},
	Content: {
		Background: '#ffffff',
		Shadow: 'rgb(160, 160, 160)'
	}
});

export const PipelineTableColors = Object.freeze({
	BlueIcon: '#196eb7',
	GrayIcon: '#7e7e7e'
});

export const CreditStatusColors = Object.freeze({
	Yellow: '#FFFF00',
	Green: '#96a81e',
	Red: '#D64000',
	Gray: '#7e7e7e',
	Blue: '#196eb7'
});
