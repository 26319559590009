import { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AuthenticationState } from '../constants/AuthenticationStateConstants';

const authTimeKey = 'authTime';

//Used as a fallback value for the msal account auth_time value.
export const setAuthTimeToNow = () => {
	localStorage.setItem(authTimeKey, new Date() / 1000);
};
export const getAuthTime = () => localStorage.getItem(authTimeKey);
export const removeAuthTime = () => localStorage.removeItem(authTimeKey);

function getMax(list, selector) {
	return Math.max(...list.map((i) => selector(i)));
}

function useAuthenticationWrapper() {
	const { accounts } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const [authenticationState, setAuthenticationState] = useState(
		AuthenticationState.NoAuthentication
	);

	useEffect(() => {
		if (isAuthenticated && accounts.length) {
			let lastEmailValidationDate = getMax(
				accounts,
				(account) => account.idTokenClaims?.extension_LastEmailValidationDate
			);
			let authTime = getMax(accounts, (account) => account.idTokenClaims?.auth_time);

			if (!authTime) {
				//Fallback value
				authTime = getAuthTime();
			}
			var secondsFromLoginToLastEmailValidation =
				(new Date(authTime * 1000) - new Date(lastEmailValidationDate * 1000)) / 1000;

			if (secondsFromLoginToLastEmailValidation > process.env.REACT_APP_MFA_TIMEOUT_SECONDS) {
				setAuthenticationState(AuthenticationState.RequiresMFA);
			} else {
				setAuthenticationState(AuthenticationState.Authenticated);
			}
		} else {
			setAuthenticationState(AuthenticationState.NoAuthentication);
		}
	}, [accounts, isAuthenticated]);

	return authenticationState;
}

export default useAuthenticationWrapper;
