import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ViewingType } from '../../../constants/DocumentViewerConstants';
import { RoutePaths } from '../../../constants/RouteConstants';
import LoadingScreen from '../LoadingScreen/LoadingScreen';

const PrivacyDisclosure = () => {
	let navigate = useNavigate();
	let { documentTypeId } = useParams();
	useEffect(() => {
		navigate(RoutePaths.ViewDocument.path, {
			state: {
				type: ViewingType.LegalDocument,
				documentTypeId: documentTypeId,
				companyId: process.env.REACT_APP_COMPANY_PRIVACY_DISCLOSURE,
				returnPage: RoutePaths.Default.path
			}
		});
	}, [navigate, documentTypeId]);

	return <LoadingScreen />;
};

PrivacyDisclosure.propTypes = {};

PrivacyDisclosure.defaultProps = {};

export default PrivacyDisclosure;
