import React, { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest, mfaMsalConfig } from '../../authConfig';
import LoadingScreen from '../pages/LoadingScreen/LoadingScreen';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import useAuthenticationWrapper from '../../utils/useAuthenticationWrapper';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { AuthenticationState } from '../../constants/AuthenticationStateConstants';

const isMultiFactorAuthenticationRequired = (authenticationState) =>
	authenticationState === AuthenticationState.RequiresMFA;

function ProtectedRoute({ children }) {
	const { inProgress } = useMsal();
	const authenticationState = useAuthenticationWrapper();

	useEffect(() => {
		const redirectToMFAPage = async () => {
			const msalInstance = new PublicClientApplication(mfaMsalConfig);

			await msalInstance.handleRedirectPromise();

			await msalInstance.loginRedirect(loginRequest).catch((e) => {
				console.error(e);
			});
		};

		if (
			isMultiFactorAuthenticationRequired(authenticationState) &&
			inProgress === InteractionStatus.None
		) {
			redirectToMFAPage();
		}
	}, [authenticationState, inProgress]);

	return isMultiFactorAuthenticationRequired(authenticationState) ? (
		<LoadingScreen />
	) : (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={loginRequest}
			loadingComponent={LoadingScreen}>
			{children}
		</MsalAuthenticationTemplate>
	);
}

ProtectedRoute.propTypes = {};

export default ProtectedRoute;
