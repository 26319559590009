import React, { useState } from 'react';
import styles from './ErrorFallback.module.css';
import { RoutePaths } from '../../../constants/RouteConstants';
import LoanFlyLogoPage from '@houseloan/loanfly-ui/components/pages/LoanFlyLogoPage';
import Button from '@houseloan/loanfly-ui/components/pageElements/Button';
import classNames from 'classnames';

// UI to be rendered in case of an error
const ErrorFallback = ({ error }) => {
	const [showError, setShowError] = useState(false);
	return (
		<div className={styles.ErrorFallback}>
			<LoanFlyLogoPage backgroundImageSrc={'../../../images/background.jpg'}>
				<div className={styles.ErrorMessageContainer} role='alert' data-testid='ErrorFallback'>
					<h2 className={styles.Title}>Something went wrong!</h2>
					<div className={styles.Message}>
						An error has occured while processing your request.
						<br />
						Please contact{' '}
						<a href='mailto:loanflyhelpdesk@houseloan.com?subject=Partner Portal Error&body=Please enter details of what action you were performing prior to receiving this error.'>
							LoanflyHelpDesk@Houseloan.com
						</a>{' '}
						with details of what action you were performing prior to receiving this error.
					</div>
					<div className={styles.Message}>
						You may use this error message in your description.
						<div
							className={showError ? styles.HideError : styles.ShowError}
							onClick={() => setShowError(!showError)}>
							{showError ? 'Hide' : 'Show'}
						</div>
						{showError && <div>{error.message}</div>}
					</div>
					<Button
						className={classNames(styles.Button, styles.Override)}
						onClick={() => (window.location.href = RoutePaths.Default.path)}
						text='Go Back'
					/>
				</div>
			</LoanFlyLogoPage>
		</div>
	);
};

ErrorFallback.propTypes = {};

ErrorFallback.defaultProps = {};

export default ErrorFallback;
