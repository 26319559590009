import { signInPolicy } from '../authConfig';

export const accountCanBeSetAsActive = (account) => {
	if (account) {
		// In this context, an account's TFP can pertain to either 'Sign in', 'Sign up' or 'MFA'
		const trustFrameworkPolicy = account.idTokenClaims?.tfp;

		if (trustFrameworkPolicy && trustFrameworkPolicy === signInPolicy) {
			return true;
		}
	}
	return false;
};

export const getAccountToSetAsActive = (accounts) => {
	for (let accountCounter = 0; accountCounter < accounts.length; accountCounter++) {
		let currentAccount = accounts[accountCounter];

		if (accountCanBeSetAsActive(currentAccount)) {
			return currentAccount;
		}
	}
	return null;
};
