import { useMsal } from '@azure/msal-react';
import styles from './SignUpPage.module.css';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { RoutePaths } from '../../../constants/RouteConstants';
import { signUpMsalConfig, signUpRequest } from '../../../authConfig';
import { retrievePartnerInviteEmail } from '../../../utils/borrowerPortalPublicApi';
import LoanFlyLogoPage from '@houseloan/loanfly-ui/components/pages/LoanFlyLogoPage';

const SignUpPage = () => {
	let navigate = useNavigate();
	let { inviteId } = useParams();
	const { instance, inProgress } = useMsal();
	const [partnerInviteEmailResult, setPartnerInviteEmailResult] = useState(null);
	const [error, setError] = useState(null);

	if (error) {
		throw error;
	}

	let showInvalidLinkMessage =
		partnerInviteEmailResult && partnerInviteEmailResult.AccessRevokedDate;

	useEffect(() => {
		retrievePartnerInviteEmail({
			request: {
				PartnerPortalUserInviteId: inviteId
			}
		})
			.then((response) => {
				let result = response.data.RetrievePartnerInviteEmailResult;
				if (!result.EmailAddress) {
					throw new Error('Invite url is not valid: email address not found.');
				}
				setPartnerInviteEmailResult(result);
			})
			.catch((e) => {
				setError(e);
			});
	}, [navigate, inviteId]);

	useEffect(() => {
		if (inviteId === undefined) {
			navigate('/');
		} else if (
			!showInvalidLinkMessage &&
			partnerInviteEmailResult != null &&
			inProgress === InteractionStatus.None
		) {
			let emailAddress = partnerInviteEmailResult.EmailAddress;

			const redirectToSignUpPage = async () => {
				await instance.handleRedirectPromise();

				await instance.loginRedirect({
					...signUpRequest,
					extraQueryParameters: { email: emailAddress, uid: inviteId },
					authority: signUpMsalConfig.auth.authority,
					redirectStartPage: new URL(RoutePaths.Logout.path, process.env.REACT_APP_URL).href
				});
			};

			redirectToSignUpPage();
		}
	}, [navigate, inviteId, partnerInviteEmailResult, instance, inProgress, showInvalidLinkMessage]);

	if (showInvalidLinkMessage) {
		return (
			<LoanFlyLogoPage backgroundImageSrc={'../../../images/background.jpg'}>
				<h2 className={styles.InvalidLinkTitle}>Oops!</h2>
				<p className={styles.InvalidLinkBody}>
					Your invite link is invalid. Access might have been revoked.
				</p>
			</LoanFlyLogoPage>
		);
	} else {
		return <LoadingScreen />;
	}
};

SignUpPage.propTypes = {};

SignUpPage.defaultProps = {};

export default SignUpPage;
